import React, { useEffect, useState } from "react";
import SEO from "../components/SEO";
import HeroSection from "@src/components/partials/staff-messaging/HeroSection";
import AlternatedFeaturesSection from "@src/components/partials/staff-messaging/AlternatedFeaturesSection";
import ToggleSection from "@src/components/partials/staff-messaging/ToggleSection";
import { graphql, useStaticQuery } from "gatsby";
import window from "global/window";
import { defaultNormalizeStaticQueryResult } from "@src/utils/normalize-static-query-result";

const StaffMessaging = () => {
  const data = useStaticQuery(graphql`
    query {
      directus {
        page_staff_messaging {
          hero_image_url
          hero_highlight_image_url
          teacher_features
          leader_features
          oval_highlight {
            id
            filename_disk
          }
        }
      }
    }
  `);

  defaultNormalizeStaticQueryResult(data);

  const {
    directus: {
      page_staff_messaging: {
        teacher_features,
        leader_features,
        hero_image_url,
        hero_highlight_image_url,
        oval_highlight,
      },
    },
  } = data;

  const [leaderContent, setLeaderContent] = useState(false);

  useEffect(() => {
    if (window.location.href.includes("schoolleader")) {
      setLeaderContent(true);
    }
  }, []);

  const HeroProps = {
    hero_title: "directus.page_staff_messaging.hero_title",
    hero_highlight_image_url,
    hero_text: "directus.page_staff_messaging.hero_text",
    hero_image: hero_image_url,
  };

  const TeacherProps = {
    title: "directus.page_staff_messaging.teacher_title",
    text: "directus.page_staff_messaging.teacher_feature_text",
    content: teacher_features,
    page: "page_staff_messaging",
    featureRepeater: "teacher_features",
    featureTitle: "title_",
    featureText: "text_",
    textOnTop: false,
    oval_highlight,
  };

  const LeaderProps = {
    title: "directus.page_staff_messaging.leader_title",
    text: "directus.page_staff_messaging.leader_feature_text",
    content: leader_features,
    page: "page_staff_messaging",
    featureRepeater: "leader_features",
    featureTitle: "title_",
    featureText: "text_",
    textOnTop: false,
    oval_highlight,
  };

  return (
    <>
      <SEO
        title="Staff Messaging"
        description="The easiest way to connect with fellow teachers and staff."
        image="https://static.classdojo.com/img/2022/2/Conundrums_Worksheets/RecessDuty_StaffMessaging.png"
        twitter={{
          card: "summary_large_image",
          site: "@classdojo",
          creator: "@classdojo",
          title: "Staff Messaging",
          description: "The easiest way to connect with fellow teachers and staff.",
          image: "https://static.classdojo.com/img/2022/2/Conundrums_Worksheets/RecessDuty_StaffMessaging.png",
        }}
      />
      <HeroSection {...HeroProps} />
      <ToggleSection
        ChildrenA={<AlternatedFeaturesSection {...TeacherProps} />}
        ChildrenB={<AlternatedFeaturesSection {...LeaderProps} />}
        defaultContentCondition={leaderContent}
        defaultSwitchText={"directus.page_staff_messaging.toggle_teacher_text"}
        defaultSwitchTextMobile={"directus.page_staff_messaging.toggle_teacher_text_mobile"}
        alternateSwitchText={"directus.page_staff_messaging.toggle_leader_text"}
        alternateSwitchTextMobile={"directus.page_staff_messaging.toggle_leader_text_mobile"}
      />
    </>
  );
};
export default StaffMessaging;
